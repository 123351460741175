import React, { useContext, useEffect } from "react";
import DataContext from "../../context/DataContext";
import { sendQuestionnaireResponse } from "../../utils/verificationAPI";
import VerificationDataContext from "../../context/VerificationDataContext";
import "./Questionnaire.scss"
import { useLocation, useNavigate } from "react-router";

const Result = () => {

  const { isEligible, questionData, isQuestionnaireCompleted } = useContext(DataContext);

  const {
    leadId,
    ShowNotification,
    setIsPreLoading,
    isQuestionnaireCompletionDone,
    setIsQuestionnaireCompletionDone,
  } = useContext(VerificationDataContext);

  useEffect(() => {
    if(isQuestionnaireCompleted){

      const sendData = async () => {
        setIsPreLoading(true);

        const requestData = {
          lead_id: leadId,
          questionnaire_response: questionData,
          is_eligible: isEligible,
        };
        
        try {
          const questionnaireResponse = await sendQuestionnaireResponse(requestData);  

          if (questionnaireResponse?.status === "success") {
            setIsQuestionnaireCompletionDone(true);
          }
    
        } catch (error) {
          console.error("Error fetching data:", error.response ? error.response.data : error.message);            
          ShowNotification("error", error.message);  
        } finally {
          setIsPreLoading(false);
        }
      };

      if(leadId){
        sendData();
      }else{
        // ShowNotification("info","Please follow the flow")
      }
    }       
  }, [isQuestionnaireCompleted]);


  // useEffect(() => {
  //   const handlePopstate = () => {
  //     alert('User navigated back using the browser button');
  //   };

  //   window.addEventListener('popstate', handlePopstate);
    
  //   return () => {
  //     window.removeEventListener('popstate', handlePopstate);
  //   };
  // }, []); 

  /* Unload */

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const confirmationMessage = 'Are you sure you want to leave? Your changes may not be saved.';
      event.preventDefault();
      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

 


  return (
    <div className="result-page">
      {isQuestionnaireCompletionDone && (
        <div className="result-page-content">
          <h4>
            {isEligible ? "Thank you! 🎉" : "Thank you for your submission"}
          </h4>

          <p>
            {!isEligible
              ? "We will reach out to you on the next steps over a phone call."
              : "Your first Deep Holistics kit out of two will be shipped to your provided address shortly"}
          </p>
        </div>
      )}
    </div>
  );
};

export default Result;





