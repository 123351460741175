import React, { useContext, useEffect } from 'react'
import HomePage from './HomePage';
// import EmailVerify from './EMailVerify';
// import MobileVerify from './MobileVerify';
import VerificationDataContext from '../context/VerificationDataContext';
import VerifyOTP from './VerifyOTP';
import PaymentSDK from './PaymentSDK';

const Verification = () => {

  const { verificationLevel } =
    useContext(VerificationDataContext);

    useEffect(() => {
      const handleBeforeUnload = (event) => {
        const confirmationMessage = 'Are you sure you want to leave? Your changes may not be saved.';
        event.preventDefault();
        return confirmationMessage;
      };
  
      window.addEventListener("beforeunload", handleBeforeUnload);
  
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, []);

    return (
      <>
        {verificationLevel === "home" && <HomePage />}

        {verificationLevel === "otp" && <VerifyOTP />}

        {verificationLevel === "payment" && <PaymentSDK />}

        <div className={`footer-section`}>
          <div className="terms">
            <a href="https://www.deepholistics.com/terms" target='_blank'>Terms of service</a>
            <a href="https://www.deepholistics.com/privacy" target='_blank'>Privacy Policy</a>
          </div>
          <div className="copyright">
            © {(() => new Date().getFullYear())()} Deep Holistics. All rights
            reserved
          </div>
        </div>
      </>
    );
}

export default Verification