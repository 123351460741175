import React, { useContext, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faClose,
  faExclamation,
  faExclamationCircle,
  faIndianRupeeSign,
  faMobileScreenButton,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import VerificationDataContext from "../context/VerificationDataContext";
import { checkRazorepayPayment, getRazorepayOrder, verifyCoupon } from "../utils/verificationAPI";
import { useNavigate } from "react-router";
import { InputAdornment, TextField } from "@mui/material";
import Swal from 'sweetalert2';

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    // script.async = true;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const PaymentSDK = () => {
  /* Verification Card */
  const {
    setIsPreLoading,
    getVerificationStatus,
    verificationObj,
    ShowNotification,
    name,
    selectedCountryCode,
    mobileNumber,
    leadId,
    isPaymentLevelDone, setIsPaymentLevelDone,
    setIsVerificationCompleted,
    setIsEligible,
    setIsPaymentCompletionDone,
    isQuestionnaireCompletionDone,
    isVerificationCompletionDone
  } = useContext(VerificationDataContext);

  const { REACT_APP_RAZORPAY_KEY } = process.env;

  const navigate = useNavigate();
  
  /* Payment Integration */

  async function getOrderData() {
    setIsPreLoading(true);
    try {
      let requestData = {
        lead_id: leadId,
        coupon_code: couponCodeResponse?.is_coupon_code_valid ? couponCode : null
      };

      const getRazorepayOrderResponse = await getRazorepayOrder(requestData);

      if (getRazorepayOrderResponse?.status === "success") {
        return getRazorepayOrderResponse?.data;
      }
    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      ShowNotification("error", "Error fetching order data...");
      throw error;
    } finally {
      setIsPreLoading(false);
    }
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Raxor Pay SDK Failed...");
      return;
    }

    const orderData = await getOrderData();

    const options = {
      key: REACT_APP_RAZORPAY_KEY, // RazorPay Key,
      amount: orderData.amount.toString(),
      currency: orderData.currency,
      order_id: orderData.payment_order_id,
      name: "Deep Holistics",
      description: "Payment for Deepholistics",
      image:
        "https://smitchcarestaticfiles.blob.core.windows.net/images/smitch/dh_logo.png",
      // callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
      handler: function (response) {
        if (
          response.razorpay_order_id &&
          response.razorpay_payment_id &&
          response.razorpay_signature
        ) {
          handleCheckRazorpayPayment(response.razorpay_order_id);
          // ShowNotification("success", "Payment successful.");
        } else {
          console.info("razorpay-Success", response);
        }      
      },
      prefill: {
        // name: "Deep Holistics",
        // email: "",
        contact: mobileNumber, //selectedCountryCode?.dial_code.slice(1)
      },
      config: {
        display: {
          hide: [
            {
              method: "upi",
            },
            {
              method: "wallet",
            },
          ],
        },
      },
      method: "netbanking",
      theme: {
        color: "#3399cc",
      },
    };

    const paymentobject = new window.Razorpay(options);
    paymentobject.open();

    //Failure
    paymentobject.on("payment.failed", function (response) {
      console.info("razorpay-failure", response);
    });
  }

  const handleCheckRazorpayPayment  = async (orderID) =>{
    setIsPreLoading(true);
    try {
      let requestData = {
        payment_order_id: orderID,
      };

      const checkRazorepayPaymentResponse = await checkRazorepayPayment(requestData);

      if (checkRazorepayPaymentResponse?.status === "success") {
        if(checkRazorepayPaymentResponse?.data?.status === "SUCCESS"){
          setIsPaymentLevelDone(true)
          setIsPaymentCompletionDone(true)
          // setIsEligible(true)

          /* Route States */
          if(isQuestionnaireCompletionDone){
            setTimeout(() => {
              navigate("/result");
            },50);
          }
          
        }        
      }

    } catch (error) {
      console.error(
        "Error fetching data:",
        error.response ? error.response.data : error.message
      );
      throw error;
    } finally {
      setIsPreLoading(false);
      // setIsPaymentLevelDone(true)
    }

  }

  const [isCoupon, setIsCoupon] = useState(false)
  const [isInvalidCoupon, setIsInvalidCoupon] = useState(false)
  const [couponCode, setCouponCode] = useState("")

  const [invalidText, setInvalidText] = useState("")

  const [couponCodeResponse, setCouponCodeResponse] = useState([])

  const handleVerifyCoupon = async () =>{

    let requestData = {
      lead_id: leadId,
      code: couponCode,
    };

    if (couponCode) {
      setIsPreLoading(true);
      try {
        const verifyCouponResponse = await verifyCoupon(requestData);
        setCouponCodeResponse(verifyCouponResponse?.data);
        setIsInvalidCoupon(!verifyCouponResponse?.data?.is_coupon_code_valid);
        setInvalidText(!verifyCouponResponse?.data?.is_coupon_code_valid ? "Invalid Coupon code" : "")

      } catch (error) {
        console.error(
          "Error fetching data:",
          error.response ? error.response.data : error.message
        );
        setIsInvalidCoupon(true);
        setInvalidText(error.message)
        // ShowNotification("error", error.message);
      } finally {
        setIsPreLoading(false);
      }
    }
  }

  const formatIndianRupee = (amount) => {
    return parseFloat(amount).toLocaleString('en-IN', { maximumFractionDigits: 2 });
  };
  

  return (
    <div className="payment-section">
      <div className="verification-home">
        {!isPaymentLevelDone && (
          <>
            <h4 className="app-name">Deep Holistics</h4>
            <h4 className="welcome-text">
              Your number has been verified. You can proceed with your purchase
              of Human Token
            </h4>
          </>
        )}

        {!isPaymentLevelDone && (
          <>
            {leadId && (
              <section className="verification-info">
                <p>
                  Name: <b>{name}</b>
                </p>
                <p>
                  Mobile:{" "}
                  <b>
                    {selectedCountryCode?.dial_code} {mobileNumber}
                  </b>
                </p>
              </section>
            )}
          </>
        )}

        {!isPaymentLevelDone && (
          <>
            {/* {leadId && ( */}
            <>
              {!isCoupon && (
                <p>
                  Have a coupon code?{" "}
                  <b
                    style={{ color: "blue", cursor: "pointer" }}
                    onClick={() => {
                      setIsCoupon(true);
                    }}
                  >
                    Enter here
                  </b>
                </p>
              )}

              {isCoupon && (
                <>
                  <TextField
                    className="input-field my-2"
                    label="Coupon code"
                    type="text"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={couponCode}
                    onChange={(event) => {
                      setCouponCode(event.target.value);
                      setCouponCodeResponse([]);
                      setIsInvalidCoupon(false);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <>
                            <FontAwesomeIcon
                              icon={faClose}
                              className="mx-1"
                              onClick={() => {
                                setCouponCode("");
                                setCouponCodeResponse([]);
                                setIsInvalidCoupon(false);
                              }}
                            />
                            <b
                              className={`${
                                couponCode ? "" : "disabled-check"
                              }`}
                              onClick={() => {
                                if (couponCode) {
                                  handleVerifyCoupon();
                                }
                              }}
                            >
                              {"Apply"}
                            </b>
                          </>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {isInvalidCoupon && (
                    <p className="invalid-text">
                      {invalidText || "Invalid Coupon code"}
                    </p>
                  )}
                </>
              )}

              {couponCodeResponse?.is_coupon_code_valid && (
                <Card className="payment-details-card">
                  <p>
                    <span>
                      Deep Holistics program
                      <small>
                        <br />
                        (Incl. 18% GST)
                      </small>
                    </span>
                    <span>
                      ₹{formatIndianRupee(couponCodeResponse?.full_amount)}
                    </span>
                  </p>
                  <p>
                    <span>
                      Discount ({couponCodeResponse?.discount_percentage}%)
                    </span>{" "}
                    <span>
                      -₹
                      {formatIndianRupee(
                        couponCodeResponse?.full_amount -
                          couponCodeResponse?.discounted_amount
                      )}
                    </span>
                  </p>
                  <p>
                    <span>Total</span>
                    <span>
                      ₹
                      {formatIndianRupee(couponCodeResponse?.discounted_amount)}
                    </span>
                  </p>
                </Card>
              )}

              <section className="verification-footer-section">
                <Button
                  className="payment-button"
                  onClick={() => {
                    if (couponCode && !couponCodeResponse?.discounted_amount) {
                      Swal.fire({
                        // title: 'Hello!',
                        text: "Do you want to proceed without applying coupon?",
                        // icon: 'success',
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        confirmButtonColor: "#000000",
                        // cancelButtonColor: "#ffffff",
                        cancelButtonText: "Cancel",
                        reverseButtons: true,
                      }).then((result) => {
                        if (result.isConfirmed) {
                          displayRazorpay();
                        } else if (
                          result.dismiss === Swal.DismissReason.cancel
                        ) {
                        }
                      });
                    } else {
                      displayRazorpay();
                    }
                    //
                  }}
                >
                  Pay Now
                </Button>
              </section>
            </>
            {/* )} */}
          </>
        )}
      </div>

      {!isQuestionnaireCompletionDone && isPaymentLevelDone && (
        <div>
          <img
            src={require("../assets/img/icon-success.png")}
            alt="Paymenet-success"
            className="payment-sucess-image"
          />
          <h5>Your Payment for the Human Token has been processed.</h5>

          <p>
            We would require few information to personalize your journey. Please
            proceed to fill out the form in the button below
          </p>
          <section className="verification-footer-section">
            <Button
              className="payment-button"
              onClick={() => {
                setIsVerificationCompleted(true);
                navigate("/questionnaire");
              }}
            >
              Go to Questionnaire
            </Button>
          </section>
        </div>
      )}

      {(isQuestionnaireCompletionDone && isPaymentLevelDone) && (
        <div>
          <img
            src={require("../assets/img/icon-success.png")}
            alt="Paymenet-success"
            className="payment-sucess-image"
          />
          <h5>Payment & Questionnaire done</h5>
          <p>We will reach out to you on the next steps over a phone call</p>
          
        </div>
      )}
    </div>
  );
};

export default PaymentSDK;
