import "./App.css";
import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
// import HomePage from "./components/HomePage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Base from "./components/Base";
import Verification from "./components/Verification";
import HomePage from "./components/HomePage";
import VerifyOTP from "./components/VerifyOTP";
import PaymentSDK from "./components/PaymentSDK";
import QuestionnaireHome from "./components/Questionnaire/QuestionnaireHome";
import Result from "./components/Questionnaire/Result";

import VerificationDataContext from "./context/VerificationDataContext";
import NotFoundPage from "./components/error-pages/NotFoundPage";


function App() {

  const { isPaymentCompletionDone, isQuestionnaireCompletionDone } = useContext(VerificationDataContext);
  

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            element={
              <Base>
                <Outlet />
              </Base>
            }
          >
            <Route path="/" element={<Verification />} />
            {/* <Route path="/" element={<HomePage />} />
            <Route path="/verifyotp" element={<VerifyOTP />} />
            <Route path="/payment" element={<PaymentSDK />} /> */}
          </Route>

          {/* <Route path="/questionnaire" element={<Home />} />
          <Route path="/result" element={<Result />} /> */}

          <Route
            path="/questionnaire"
            element={
              isPaymentCompletionDone ? (
                <QuestionnaireHome />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/result"
            element={
              isQuestionnaireCompletionDone ? <Result /> : <Navigate to="/" />
            }
          />

          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
