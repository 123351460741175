import React, { useContext, useRef } from "react";
import "./Verification.scss";
import VerificationDataContext from "../context/VerificationDataContext";
import preloaderGif from "../assets/img/preloader-1.gif";
import otpLoaderStartGif from "../assets/img/otpLoader.gif";
import otpLoaderEndGif from "../assets/img/otpLoaderEnd.gif";

const Base = ({ children}) => {
  
  const { isLoading, isPreLoading, isLoadingColor, isLoadingEnd } = useContext(VerificationDataContext);
  const divRef = useRef(null);

  return (
    <div>
      <section className="main-component" ref={divRef}>
        {children}
      </section>

      {isLoading && (
        // <div className="loader-bg">
        // <div className={`verification-loader`}>
        //   <div className={`circle-loader ${isLoadingColor}`}>
        //     <div className="status draw"></div>
        //   </div>
        // </div>
        // </div>
        <div className="preloader-section">
          {isLoadingEnd ? (
            <img
              src={otpLoaderEndGif}
              alt="OTP Loading..."
              className="otp-loader"
            />
          ) : (
            <img
              src={otpLoaderStartGif}
              alt="OTP Loading..."
              className="otp-loader"
            />
          )}
        </div>
      )}

      {isPreLoading && (
        <div className="preloader-section">
          <img src={preloaderGif} alt="PreLoading..." />
        </div>
      )}
    </div>
  );
};

export default Base;
