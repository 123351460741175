import React from "react";
import "./NotFoundPage.scss";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";

const NotFoundPage = () => {
  
  const navigate = useNavigate();

  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-10 col-sm-offset-1  text-center">
              <div className="four_zero_four_bg"></div>

              <div className="contant_box_404">
                <h3 className="h2">Look like you're lost</h3>
                <p>the page you are looking for is not available!</p>
                <Button
                  className="link_404"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Go to Home
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
